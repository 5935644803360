import * as React from "react";

import Layout from "../components/Base/Layout";
import LogInForm from "../components/Login/LoginForm";
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const LogIn = () => {
  return (
    <Layout>
      <UnAuthContent>
        {/* <SvgBackground position="absolute" bottom="0" right="0"></SvgBackground> */}
        <LogInForm />
      </UnAuthContent>
    </Layout>
  );
}

export default LogIn

export const Head = () => (
  <SEO title="AceMedical Wholesale | Login" description="A Wholesale Medical company. Login to continue your shopping for wholesale medical products." />
)